.Header {
    text-align: center;
    font-size: calc(12px + 2vmin);
    color: #E6E8E6;
}

.Highlight {
    color: #FDCA40;
    margin: 0;
}

.Header .Text {
    color: #E6E8E6;
    margin: 0;
}

.Header .Subtext {
    font-size: calc(9px + 1vmin);
    color: #b9c7c2;
    margin: 0;
}