.NavHeader {
    display: flex;
    flex-wrap: wrap;
}

.PortfolioLogo {
    height: 80px;
    width: 100%;
    color: #E6E8E6;
}

.PageTitle {
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    margin-top: 10px;
    margin-bottom: 0;

    font-weight: 300;
    font-size: 32px;
}
