.ResponsiveColums {
    display: flex;
    flex-wrap: wrap;
}

.ResponsiveColums > * {
    width: 100%;
    padding: 1rem;
}

@media (min-width: 768px) {
    .ResponsiveColums > * {
        width: 50%;
        padding: 0;
    }
}

.FullStar {
    color: #f1c40f;
}

.EmptyStar {
    color: #7d888f;
}

.NavHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Resume {
    background-color: #02122B;
    color: #E6E8E6;
}

.Heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
}

.Heading > * {
    align-content: center;
    font-weight: 250;
    font-size: 30px;
    margin: 0;
    padding: 0;
}

.AboutMe, .Skills, .Education, .WorkExperience {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 30px;
}

.AboutMe > .Heading,
.Skills > .Heading,
.Education > .Heading,
.WorkExperience > .Heading {
    display: block;
    font-weight: 250;
    font-size: 30px;
    width: 100%;
    float: left;
    margin: 10px 0 ;
    padding: 0;
}

.AboutMe > .Body {
    margin: 0;
    padding: 0;
}

.Skill {
    display: flex;
    margin: 10px 0;
    padding: 0;
}

.Skill > .Name {
    font-weight: 250;
    font-size: 18px;
    width: 80px;
    margin: 0 10px 0 0;
    padding: 0;
}

.Program {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 20px;
}

.Program > h3, .Job > h3 {
    font-weight: 400;
    font-size: 22px;
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0;
}

.Program > h4, .Job > h4 {
    font-weight: 300;
    font-size: 17px;
    width: 100%;
    margin: 0 10px 0 0;
    padding: 0;
}